import * as Constants from '../../constants';

export const cv = {
    get links(): ReturnType<typeof Constants.getCVLinks> {
        return Constants.getCVLinks(this);
    },
    path: 'cv',
    PDFTitle: 'Ściągnij PDF',
    PDFPrefix: Constants.CV_PDFPrefix,
    general: `Jestem samodzielnym księgowym z 5-letnim doświadczeniem w księgowości oraz 8-letnim doświadczeniem w obszarze podatków zdobytym na Białorusi. 
                Obecnie poszerzam swoje kompetencje zawodowe w Polsce, regularnie biorąc udział w kursach i szkoleniach oraz śledząc aktualne zmiany w przepisach.
                Jestem gotowa szybko dostosować się do wymogów polskiego rynku i przyczynić się do rozwoju firmy.`,
    consent: `Wyrażam zgodę na przetwarzanie moich danych osobowych w celu prowadzenia rekrutacji na aplikowane przeze mnie stanowisko.`,
    skills: {
        title: 'UMIEJĘTNOŚCI',
        all: [
            `znajomość Comarch Optima`,
            `znajomość MS Office`,
            `znajomość ustawy o rachunkowości`,
            `znajomość przepisów podatkowych`,
            `świetna organizacja pracy`,
            `krupulatność i odpowiedzialność`,
            `umiejętności komunikacyjne`,
            `umiejętność pracy w zespole`,
        ],
    },
    languages: {
        title: 'JĘZYKI',
        all: [
            { language: 'Język polski:', level: Constants.levelPL },
            { language: 'Język angielski:', level: Constants.levelENG },
            { language: 'Język rosyjski:', level: 'doskonały' },
        ],
    },
    university: {
        title: 'WYKSZTAŁCENIE',
        all: [
            {
                years: `2004 - 2009`,
                nameUniversity: `Homelski Uniwersytet Państwowy im. Franciszka Skaryny`,
                url: `https://www.gsu.by/`,
                specialityTitle: 'kierunek',
                speciality: `ekonomia, działalność handlowa`,
            },
        ],
    },
    courses: {
        title: 'DODATKOWE KURSY',
        all: [
            {
                years: `2024 - 2025`,
                nameUniversity: `Stowarzyszenie księgowych w Polsce`,
                url: `https://skwp.pl/`,
                specialityTitle: 'kierunek',
                speciality: `Specjalista ds. rachunkowości (samodzielna księgowa)`,
                certPath: Constants.certPL_2,
            },
            {
                years: `2023 - 2024`,
                nameUniversity: `Stowarzyszenie księgowych w Polsce`,
                url: `https://skwp.pl/`,
                specialityTitle: 'kierunek',
                speciality: `Księgowa (pełna księgowość)`,
                certPath: Constants.certPL_1,
            },
            {
                years: `2023 - w trakcie szkoleń`,
                nameUniversity: `Szkoła policealna Cosinus`,
                url: `https://www.cosinus.pl/`,
                specialityTitle: 'kierunek',
                speciality: `Administracja`,
                certPath: /*Constants.certPL_1*/ '',
            },
            /*{
                years: `2021 - 2022`,
                nameUniversity: `The Rolling Scopes School`,
                url: `https://rs.school/`,
                specialityTitle: 'kierunek',
                speciality: `JavaSkrypt, programista frontendowy`,
                certPath: Constants.certRS,
            },*/
            /*  {
                years: `2013`,
                nameUniversity: `Stowarzyszenie księgowych w Białorusi`,
                specialityTitle: 'kierunek',
                speciality: `Księgowa (pełna księgowość)`,
                certPath: Constants.certBY_1,
            }, */
        ],
    },
    experience: {
        title: 'DOSWIADCZENIE ZAWODOWE',
        all: [
            {
                year: `2024 - obecnie`,
                place: `Biuro rachunkowe JADMAR sp. z o.o., Polska (Wrocław)`,
                profession: `Samodzielna księgowa`,
                experiences: [`dbanie o prawidłowy obieg dokumentów;`],
            },
            {
                year: `2017 - 2021; 2023 - 2024`,
                place: `FotoCeramika sp. z o.o., Białoruś`,
                profession: `Samodzielna księgowa`,
                experiences: [
                    `dbanie o prawidłowy obieg dokumentów;`,
                    `prowadzenie ewidencji księgowych, operacji kasowych, przychodów i kosztów księgowych;`,
                    `kontrola rozrachunków z dostawcami i odbiorcami;`,
                    `rozliczenie kosztów, w tym w celach podatkowych;`,
                    `dokonywanie płatności, prowadzenie rachunków bieżących organizacji;`,
                    `sporządzanie sprawozdań finansowych (bilans, rachunek zysków i strat) i statystycznych;`,
                    `obliczanie podatków (VAT, CIT, PIT) oraz przygotowywanie i składanie deklaracji podatkowych;`,
                    `współpraca z instytucjami zewnętrznymi (urząd skarbowy, ZUS, inne);`,
                    `praca z systemem faktur elektronicznych;`,
                    `przeprowadzenie inwentaryzacji aktywów obrotowych, środków trwałych, zobowiązań;`,
                    `ewidencja środków trwałych;`,
                    `naliczanie listy płac.`,
                ],
            },
            {
                year: `2009 - 2017; 2021 - 2022`,
                place: `Urząd skarbowy, departament kontroli nad osobami prawnymi, Białoruś`,
                profession: `Inspektor podatkowy`,
                experiences: [
                    `przeprowadzenie kontroli podatkowej osób prawnych;`,
                    `sprawdzanie przestrzegania przez organizacje przepisów podatkowych i rachunkowych;`,
                    `kontrola kompletności odzwierciedlenia przychodów organizacji w rachunkowości i sprawozdaniu podatkowym;`,
                    `sprawdzenie legalności zaliczenia wydatków organizacji do kosztów uwzględnianych dla celów podatkowych;`,
                    `sprawdzanie poprawności rozliczeń podatkowych przez organizacje (podatek VAT, CIT, PIT, podatek gruntowy, podatek od nieruchomości).`,
                ],
            },
        ],
    },
    interests: {
        title: 'ZAINTERESOWANIA',
        all: [`Siłownia`, `czytanie`, `zdrowy tryb życia`, `rozwój cech osobistych i zawodowych`],
    },
};
