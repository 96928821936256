import * as Constants from '../../constants';

export const cv = {
    get links(): ReturnType<typeof Constants.getCVLinks> {
        return Constants.getCVLinks(this);
    },
    path: 'cv',
    PDFTitle: 'Download PDF',
    PDFPrefix: Constants.CV_PDFPrefix,
    general: `I am an independent accountant with 5 years of experience in accounting and 8 years of experience in the field of taxation, both acquired in Belarus. 
              Currently, I am expanding my professional competencies in Poland by regularly participating in courses and training sessions, and keeping up to date with the
              latest regulatory changes. I am ready to quickly adapt to the requirements of the Polish market and contribute to the company's growth`,
    consent: `I consent to the processing of my personal data for the purpose of recruiting for the position I am applying for`,
    skills: {
        title: 'SKILLS',
        all: [
            `knowledge of Comarch Optima`,
            'knowledge of MS Office',
            'excellent work organization',
            'scrupulity and responsibility',
            'communication skills',
            'ability to work in a team',
        ],
    },
    languages: {
        title: 'LANGUAGE SKILLS',
        all: [
            { language: 'Polish language:', level: Constants.levelPL },
            { language: 'English language:', level: Constants.levelENG },
            { language: 'Russian language:', level: 'native' },
        ],
    },
    university: {
        title: 'EDUCATION',
        all: [
            {
                years: `2004-2009`,
                nameUniversity: `Francisk Skorina Gomel State University`,
                url: `https://www.gsu.by/`,
                speciality: `economics`,
                specialityTitle: 'speciality',
            },
        ],
    },
    courses: {
        title: 'ADDITIONAL COURSES',
        all: [
            {
                years: `2024 - 2025`,
                nameUniversity: `Association of Accountants of Poland`,
                url: `https://skwp.pl/`,
                speciality: `Senior Accountant`,
                specialityTitle: 'speciality',
                certPath: Constants.certPL_2,
            },
            {
                years: `2023-2024`,
                nameUniversity: `Association of Accountants of Poland`,
                url: `https://skwp.pl/`,
                speciality: `Accountant`,
                specialityTitle: 'speciality',
                certPath: Constants.certPL_1,
            },
            {
                years: `2023 - currently undergoing training`,
                nameUniversity: `Cosinus Post-seconary School`,
                url: `https://www.cosinus.pl/`,
                speciality: `Administration`,
                specialityTitle: 'speciality',
                certPath: /*Constants.certPL_1*/ '',
            },
            /*{
                years: `2021-2022`,
                nameUniversity: `The Rolling Scopes School`,
                url: `https://rs.school/`,
                speciality: `JavaScript/Front-end`,
                specialityTitle: 'speciality',
                certPath: Constants.certRS,
            },*/
            /* {
                years: `2013`,
                nameUniversity: `Association of Accountants of Belarus`,
                speciality: `Accountant`,
                specialityTitle: 'speciality',
                certPath: Constants.certBY_1,
            }, */
        ],
    },
    experience: {
        title: 'EXPERIENCE',
        all: [
            {
                year: '2024 - Present',
                place: 'Accounting Office JADMAR sp. z o.o., Poland (Wroclaw)',
                profession: 'Independent Accountant',
                experiences: ['Ensuring proper document circulation;'],
            },
            {
                year: `2017-2021, 2023-2024`,
                place: `PhotoCeramics company, Belarus`,
                profession: `Accountant`,
                experiences: [
                    `ensuring proper document circulation;`,
                    `maintaining accounting records and reconciling them with general ledger accounts;`,
                    `making payments and managing the organization’s current accounts;`,
                    `controlling settlements with suppliers and customerss;`,
                    `keeping records of accounting costs, reconciling them with general ledger accounts;`,
                    `maintaining revenue records for accounting and tax purposes;`,
                    `maintaining cash operations records;`,
                    `maintaining accounting cost records and reconciling them with general ledger accounts;`,
                    `accounting for costs, including for tax purposes;`,
                    `preparing financial statements (balance sheet, profit and loss statement);`,
                    `preparing statistical reports;`,
                    `calculating taxes (VAT, CIT, PIT);`,
                    `preparing and submitting tax declarations (VAT, CIT, PIT);`,
                    `cooperating with external institutions (tax office, Social Insurance Institution, others);`,
                    `working with the electronic invoice system;`,
                    `conducting inventory of current assets, fixed assets, liabilities;`,
                    `recording fixed assets;`,
                    `payroll calculation.`,
                ],
            },
            {
                year: `2009-2017, 2021-2022`,
                place: `Tax office, department of control over legal entities, Belarus`,
                profession: `Tax inspector`,
                experiences: [
                    `conducting tax audits of legal entities;`,
                    `checking organizations compliance with tax and accounting regulations;`,
                    `control of the completeness of reflection of the organization's revenues in accounting and tax reports;`,
                    `verifying the legality of including the organization's expenses as costs included for tax purposes;`,
                    `checking the correctness of tax settlements by organizations (VAT, personal and corporate income tax, land tax, real estate tax).`,
                ],
            },
        ],
    },
    interests: {
        title: 'INTERESTS',
        all: [`Gym`, `reading`, `healthy lifestyle`, `development of personal and professional qualities`],
    },
};
